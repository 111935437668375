@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

body,
html {
    margin: 0;
    padding: 0;
}



.App {
    text-align: center;
    margin: 0;
    padding: 0;
    background: #000;
    color: #fff;
    font-family: "Secular One";
    letter-spacing: 1px;
}
a {
    text-decoration: none;
    font-family: "Secular One";
    letter-spacing: 1px;
}
::placeholder {
    font-family: "Secular One";
}

/* TOOLBAR */

.toolbar {
    width: 100%;
    height: 70px;
    background: #000;
    color: #fff;
    align-items: center;
    position: fixed;
    z-index: 200;
}
.toolbar-content {
    width: 90%;
    height: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}
.toolbar-links {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.toolbar-links h1 {
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 18px;
    border: 2px solid #000;
    padding: 5px 10px;
    transition: .35s;
}
.toolbar-links h1:hover {
    border: 2px solid #fff
}

/* DRAWER */

.drawer {
    background: #000;
    position: fixed;
    bottom: 0%;
    left: 0;
    width: 100%;
    height: 85%;
    z-index: 200;
    transform: scaleX(0);
    transition: transform .1s ease-out;
    overflow: hidden;
    border-top: 6px solid #fff;
}
.drawer.open {
    transform: scalex(1);
}
.drawer-links h1 {
    color: #fff;
    letter-spacing: 2px;
}
.drawer-links {
    margin-top:10%;
    margin-bottom: -20px;
    padding-bottom: 50px;
}

/* DRAWER BUTTON */

.drawer-button {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 35px;
    width: fit-content;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
}

.drawer-button-line1 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease-in-out;

}
.drawer-button-line2 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line3 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease;
    margin-left: -3px;
}
.drawer-button-line4 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line5 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line6 {
    background: #fff;
    height: 35px;
    width: 3px;
    transition: 0.3s ease-in-out;
    margin-left: 5px;
}
.drawer-button-line7 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line8 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line9 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line10 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line11 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: 5px;
}
.drawer-button-line12 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line13 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line14 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}
.drawer-button-line15 {
    background: #fff;
    height: 35px;
    width: 3px;
    border-radius: 50px;
    transition: 0.3s ease-in-out;
    margin-left: -3px;
}


#hamburger:hover .drawer-button-line1 {
    margin-right: 1px;
}
#hamburger:hover .drawer-button-line2 {
    transform: rotate(-27deg);
    margin-left: 4px;
}
#hamburger:hover .drawer-button-line3 {
    transform: rotate(27deg);
    margin-left: 12px;
}
#hamburger:hover .drawer-button-line4 {
    margin-left: 5px;
}


#hamburger:hover .drawer-button-line5 {
    margin-left: 5px;
}


#hamburger:hover .drawer-button-line6 {
    margin-left: 20px;
}
#hamburger:hover .drawer-button-line7 {
    transform: rotate(-90deg);
}


#hamburger:hover .drawer-button-line8 {
    transform: rotate(23deg);
    margin-left: 20px;
}
#hamburger:hover .drawer-button-line9 {
    transform: rotate(-23deg);
    margin-left: 7px;
}


#hamburger:hover .drawer-button-line10 {
    margin-left: 8px;
}
#hamburger:hover .drawer-button-line11 {
    transform: rotate(-90deg);
    margin-left: 17.5px;
}
#hamburger:hover .drawer-button-line12 {
    transform: rotate(45deg);
}
#hamburger:hover .drawer-button-line13 {
    transform: rotate(-47deg);
}


#hamburger:hover .drawer-button-line14 {}

#hamburger:hover .drawer-button-line15 {
    transform: rotate(90deg);
    margin-left: 25px;

}

/* BACKDROP */

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    z-index: 100;
}

/* PAGE */

.home-page {
    padding-top: 71.5px;
    width: 100%;
}
.page {
    width: 90%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}
.main-button {
    width: 100%;
    border: none;
    background: #fff;
    color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
}
.back-button {
    width: 100%;
    border: none;
    background: #fff;
    color: #000;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    border-radius: 4px;
    outline: none;
}
.explore-thumbnail {
    width: 100%;
    max-width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    background: #333;
}
input {
    width: 100%;
    padding: 10px 0;
    margin-bottom: 5px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    color: #fff;
    background: #111;
    letter-spacing: 1px;
    outline: none;
    font-family: "Montserrat";
}
textarea {
    width: 100%;
    height: 60px;
    resize: none;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    color: #fff;
    background: #111;
    outline: none;
       font-family: "Secular One";
       letter-spacing: 1px;
}

/* FOOTER */
.footer {
    background: #fff;
}

.footer-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    letter-spacing: 1px;
}
.footer-content p {
    color: #000;
}
.footer-socialmedia {
    padding-top: 20px;
    padding-bottom: 20px;
    color: #000;
    display: flex;
    align-items: center;
}
.footer-socialmedia a {
    color: #000;
}
.footer-icon {
    margin-left: 10px;
}
.footer-credit {
    color: #666;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 1px;
}

/* GRID ROW */

.grid-first-row {
    display: flex;
}
.grid-first-row-container1 {
    width: 60%;
    height: 300px;
    border-top: 2px solid #fff;
    border-right: 1px solid #fff;
    border-left: none;
    border-bottom: none;
}
.grid-first-row-container2 {
    width: 40%;
    height: 300px;
    border-top: 2px solid #fff;
    border-left: 1px solid #fff;
    border-right: none;
    border-bottom: none;
}
.grid-second-row {
    display: flex;
}
.grid-second-row-container1 {
    width: 30%;
    height: 300px;
    border-top: 2px solid #fff;
    border-right: 1px solid #fff;
    border-left: none;
    border-bottom: none;
}
.grid-second-row-container2 {
    width: 70%;
    height: 300px;
    border-top: 2px solid #fff;
    border-left: 1px solid #fff;
    border-right: none;
    border-bottom: none;
}

.page-button-section {
    display: flex;
    background: #111;
    margin-left: auto;
    margin-right: auto;
}
.page-button-section1 {
    width: 50%;
    text-align: center;
    background: #fff;
    color: #000;
    font-size: 7px;
    margin-left: auto;
}
.page-button-section2 {
    background: #111;
    width: 50%;
    font-size: 7px;
    text-align: center;
    margin-right: auto;
}
.page-button-section2 h1 {
color: #fff
}
.page-text {
    text-align: left;
}

@media (max-width: 850px) {
    .toolbar-links {
        display: none;
    }
    .toolbar-logo {
        display: none;
    }
}
@media (min-width: 851px) {
    
    .drawer-button {
        display: none;
    }
    .drawer {
        display: none;
    }
    .backdrop {
        display: none;
    }

}

.tshirt {
    width: 90%;
    max-width: 300px;
    height: 300px;
    background: #999;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}